$bgTournament:#389466;
$bgListMatch:#27293d;
$bgListMatchgd:linear-gradient(-45deg, #212639 0, #141d2e 100%);
$fontColorMain:rgb(240, 240, 240);
$bgMain:#1e1d30;
$fontMain:sans-serif;
$text-right:right;
$text-center:center;
$text-left:left;
$text-white:white;
$highlight-btn:#1ca5d4;
$focus-row:#359892;
$bgGdMain:linear-gradient(-45deg, #16a569 0, #07926c 100%);
.fading{animation:fading 1s}@keyframes fading{0%{opacity:0}100%{opacity:1}}
.fadout{animation:fadout 1s}@keyframes fadout{0%{opacity:1}100%{opacity:0}}
body{
    background-color: $bgMain;
    font-family: $fontMain;
    font-size: small;
    margin: 0px;
    //background-image: url('https://mvpkick.com/img/asset/background-forpc.webp');
    background-repeat: no-repeat;
    background-position: top;
    background-attachment: fixed;
    background-image: linear-gradient( 180deg, #0c1928, #27293d);

}
.oddsZoneLs{
    //display: none !important;
    margin: auto;
    text-align: center;
}
.oddsZoneLsBox {
    border-radius: 0.25rem;
    padding-top: 5px;
    padding-bottom: 5px;
}
.oddsZoneLsBox div.col-3 {
    margin-top: 1px;
    background-color: rgba(0,0,0,0.25);
    margin-top: 1px;
    padding-top: 3px;
    padding-bottom: 3px;
}
form.cmForm {
    text-align: center;
}
.mainBoxNews{
    text-align: center;
}
.f-left{
    float: left;
}
.container{
    max-width: 1000px;
    margin: auto;
}
.heading-hl {
    background-color: $highlight-btn;
    padding: 5px;
    border-radius: 0.25rem;
}
.cmBlock {
    background-image: linear-gradient(45deg, #030f1e, transparent);
    margin: 5px;
    padding: 5px;
}
.dateCmRow {
    color: gray;
    font-size: 12px;
}
.desCmRow {
    background-color: #161f30;
    padding: 5px;
    margin: 5px;
}

.infoUser {
    max-width: 600px;
    width: 90%;
    text-align: center;
    margin: auto;
    background-color: #13141d;
    padding: 20px;
    border-radius: 0.25rem;
    margin-top: 10px;
    margin-bottom: 10px;
}
.infoUser div.row {
    background-color: #131d2d;
    margin: 10px;
    padding: 5px;
    border-radius: 0.25rem;
}


.RecMatchZone {
    //background-color: $bgMain;
    margin: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    border-radius: 0.25rem;
}
textarea {
    width: 95%;
    color: white;
    background-color: #161f30;
}
.topbar-nav,.footerbar-nav,.footerBar {
    background-color: #13141d;
    color: white;
    text-align: $text-center;
    z-index: 500;
}
.topbar-nav,.footerbar-nav{
    position: fixed;
    width: 100%;
}
.footerbar-nav{
    bottom: 0;
    right: 0;
    display: none;
}
.footerBar{
    padding: 10px;
}
.h2hw,.h2hd,.h2hl{
    border-radius: 1rem;
    padding: 5px;
}
.iconInFmt{
    width: 15px;
    height: 15px;
}
.h2hw{background-color: #15a46a;}
.h2hd{background-color: orange;}
.h2hl{background-color: #f92a38;}
.nav-item, .nav-item-last {
    display: inline-table;
    font-size: 14px;
    padding: 15px;
    cursor: pointer;
    span{
        vertical-align: super;
    }
}
.nav-item{
    border-right: 1px solid #1e1e2f;
}
.nav-item-last{
    border-right: 0px
}
.nav-item-footerbar{
    display: inline-table;
    width: 20%;
    font-size: 14px;
    padding: 8px;
    padding-left: 0px;
    padding-right: 0px;
    z-index: 800;
    cursor: pointer;
}
.topbar-nav div:hover, .footerbar-nav div:hover{
    background-color: $highlight-btn;
}
.icon-nav{
    width: 25px;
    height: 25px;
    padding-right: 5px;
}
.boxContentPage{
    background-color: rgba(0, 0, 0, 0.25);
    padding: 10px;
    border-radius: 0.25rem;
    text-align: $text-center;
    max-width: 1000px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
.tourStartTded span {
    background: linear-gradient(-45deg, #16a569 0, #07926c 100%);
    padding: 10px;
    border-radius: 0.75rem;
    font-size: 13pt;
}
.tourStartTded{
    margin-top: 10px;
    overflow: hidden;
    padding: 5px;
}
.thumbTdedImg{
    background-image: url('https://mvpkick.com/img/asset/bg-thumb-tded.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 300px;
    text-align: center;
    border-radius: 0.5rem;
}
.tourNameTded {
    font-size: 13pt;
    background-color: rgba(0,0,0,0.5);
}
.listTded {
    margin: 10px;
    padding: 10px;
    background-image: linear-gradient(180deg, #1a263c, #22374e);
    border-radius: 0.25rem;
    margin: 10px;
    padding: 10px;
    box-shadow: 5px 10px #1e1d30;
}
a{
    color: $text-white;
    text-decoration: none;
}
.App{
    max-width: 1500px;
    margin: auto;
    color:$fontColorMain;
    padding: 8px;
    padding-top: 70px;
}
.tournament {
    //background-color: $bgTournament;
    background : $bgGdMain;
    padding: 5px;
    border-radius: 0.25rem;
}
.cardNews{
    margin: 10px;
    padding: 10px;
    background-image: linear-gradient(180deg, #1a2640, #29536b);
    border-radius: 0.25rem;
    margin: 10px;
    padding: 10px;
    box-shadow: 5px 10px #191919;
}
.listMatch {
    background-color: $bgListMatch;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 1px;
    margin-bottom: 1px;
    border-radius: 0.25rem;
    background: $bgListMatchgd;
    div{
        vertical-align: middle;
    }
}
.ThumbnailNewsSidebar {
    width: 100%;
    min-height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.8;
    border-radius: 0.5rem;
}
.posterNewsContentpost{
    width: 90%;
    border-radius: 0.5rem;
}
.contentNewsDetails{
    text-align: center;
}
.contentNewsDetails{
    background-color: rgba(52,85,109,0.25);
    padding: 15px;
    border-radius: 0.25rem;
    box-shadow: 5px 10px #1e1d30;
    position: relative;
    font-size: 11pt;
}
.mainBoxNews{
    margin: auto;
}
.sidebarNews{
    vertical-align: top;
    margin-right: 15px;
    margin-left: 15px;
}
.listMatch-none-py {
    background-color: $bgListMatch;
    margin-top: 1px;
    margin-bottom: 1px;
    border-radius: 0.25rem;
}
.ThumbnailNews{
    width: 100%;
    min-height: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.9;
    border-radius: 0.5rem;
    

}
.helloFanball {
    background: rgba(0,0,0,0.25);
    padding: 5px;
    text-align: center;
    color: #34dea9;
}
.wicroeballTitle{
    font-size: 11pt;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 5px;
    margin-top: 5px;
    border: 1px solid #16a46a;
    border-style: dashed;
    border-radius: 0.25rem;
}
.ThumbnailNews:hover,.ThumbnailNewsSidebar:hover{
    transform: scale(1.2);
    transition: all .5s;
    opacity: 1;
}
.cardRankTop {
    background-image: linear-gradient( 180deg, #1a263c, #22374e);
    border-radius: 0.25rem;
    margin: 10px;
    padding: 10px;
    box-shadow: 5px 10px #1e1d30;
    position: relative;
}
.leagueRankTop {
    background-color: #171b29;
}
.headingNewsPage h3{
    background-color: rgba(0, 0, 0, 0.25);
    padding: 10px;
}
.headingNewsPage h4, .headingNewsPage h1 {
    background-color: rgba(0, 0, 0, 0.25);
    padding: 5px;
}
.text-right{
    text-align: $text-right;
}
.text-center{
    text-align: $text-center;
}
.text-left{
    text-align: $text-left;
}
h1 {
    font-size: 1.5em;
}
.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-8, .col-9, .col-10, .col-12, .wp-20{
    display: inline-block;
}
.col-1{
    width: 8.33%;
}
.col-2{
    width: 16.66%;
}
.col-3{
    width: 25%;
}
.col-4{
    width: 33.33%;
}
.col-5{
    width: 41.66%;
}
.col-6{
    width: 50%;
}
.col-8{
    width: 66.66%;
}
.col-9{
    width: 75%;
}
.col-10{
    width: 83.33%;
}
.col-12{
    width: 100%;
}
.pa-2{
    padding: 5px;
}
.pa-3{
    padding: 10px;
}
.px-0{
    padding-right: 0px;
    padding-left: 0px;
}
.px-3{
    padding-right: 10px;
    padding-left: 10px;
}
.py-2{
    padding-top: 5px;
    padding-bottom: 5px;
}
.py-3{
    padding-top: 10px;
    padding-bottom: 10px;
}
.pb-2{
    padding-bottom: 5px;
}
.pb-3{
    padding-bottom: 10px;
}
.my-1{
    margin-top: 1px;
    margin-bottom: 1px;
}
.my-2{
    margin-top: 5px;
    margin-bottom: 5px;
}
.my-3{
    margin-top: 10px;
    margin-bottom: 10px;
}
.my-5{
    margin-top: 20px;
    margin-bottom: 20px;
}
.mt-3{
    margin-top: 10px;
}
.mt-5{
    margin-top: 20px;
}
.mb-2{
    margin-bottom: 5px;
}
.mb-3{
    margin-bottom: 10px;
}
.mx-3{
    margin-left: 10px;
    margin-right: 10px;
}
.incBox div.listMatch {
    padding-right: 10px;
    padding-left: 10px;
}
.incListHTFT.listMatch {
    text-align: center;
    background-color: rgba(0,0,0,0.25);
    background: linear-gradient(-45deg, #272727 0, #0e0e0e 100%);
    font-weight: 600;

}
.boxVSh2h {
    background-color: #27293d;
    padding: 3px;
    border-radius: 0.25rem;
    background-image: linear-gradient(180deg, #1a263c, #22374e);
}
.progressColTded {
    max-width: 90%;
    margin: 15px;
}
.boxProgressTded{
    background-color: rgba(0,0,0,0.25);
    padding: 5px;
    margin-top: 10px;
}
.topLogoH2h, .boxVSh2h{
    max-width: 500px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
.nav-selectDate{
    text-align: $text-center;
    border-radius: 0.25rem;
    .selectDate{
        cursor: pointer;
        background-color: #27293d;
        width: 20%;
        padding-top: 5px;
        padding-bottom: 5px;
        span{
            vertical-align: -webkit-baseline-middle;
        }
    }
    .selectDate.active {
        background-color: $highlight-btn;
    }
    
}
.selectPlay {
    background: #111521;
    margin-top: 1px;
    padding: 2px;
}

.btn-gradient {
    background-image: linear-gradient(
        45deg, #0c986b, #086686);
            border: 0;
            color: white;
            padding: 5px;
            margin: 5px;
            border-radius: 0.25rem;
  }
.boxbannerZone{
    text-align: center;
}
.bannerHomepage {
    width: 100%;
    margin-top: 15px;
}
.active {
    background-color: $highlight-btn !important;
}
.selTour,.selCountMacth{
    width: 90%;
    max-width: 500px;
    padding: 5px;
    background-color: #27293d;
    color: white;
    border-color: #7f808a;
    border-radius: 0.25rem;
}
.ftScore{
    span {
        background-color: #484848;
        padding-left: 7px;
        padding-right: 7px;
        padding-top: 3px;
        padding-bottom: 3px;
        border-radius: 0.25rem;
    }
} 
.boxContentPage a {
    color: #00ff89;
}
.mainFmtBox{
    background-image: url("https://mvpkick.com/img/asset/background_fmt.jpg");
}
.btn{
    cursor: pointer;
}
.btn-tded-news{
    background-color: #13141d;
    text-align: center;
    font-size: 13pt;
    margin: 10px;
    //max-width: 400px;
}
.btn-tded-news:hover{
    background-color: $highlight-btn !important;
}
.btn-h2h, .btn-laser, .btn-tded-news {
    border-radius: 0.25rem;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 3px;
    padding-right: 3px;
}
.boxReviewTded{
    background: linear-gradient(180deg, #232526 0, #2f3235 100%);
    margin: 10px;
    border-radius: 0.25rem;
}
.boxFunTong{
    margin: auto;
    margin-top: 10px;
}
.tdedContentMainBlock.containerLs {
    background-color: rgba(0,0,0,0.1);
    border-radius: 0.5rem;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 15px;
    padding-top: 15px;
}
.btn-h2h{
    background-color: $bgListMatch;
    margin-right: 1px;
    margin-left: 1px;
}
.btn-laser{
    color: rgb(28, 212, 166);
    border: 1px solid rgb(28, 212, 166);
    cursor: pointer;
}
.resultTdedPoint{
    border: 3px solid #15a469;
    border-style: dotted;
    margin: 10px;
}
.logoLikeTded{
    width: 50px;
}
.funtongScoreTded{
    background-color: rgba(0,0,0,0.25);
    padding: 10px;
    font-size: 12pt;
    border-radius: 0.5rem;
}
.funtongCommentTded{
    background-color: rgba(0,0,0,0.25);
    padding: 10px;
}
.H2hTopLogo,.H2hTopLogoTded{
    background-color: rgba(30, 29, 48, 0.75);
    padding: 15px;
    border-radius: 0.25rem;
}
.H2hTopLogoTded{
    background-color: #00000000;
}
.countdownBoxNum{
    background-color: rgba(30, 29, 48, 0.75);
    
    border-radius: 0.25rem;
    margin: 3px;
}
.cdListMatch{
    background-color: rgba(0,0,0,0.25);
}
.rowTypePlayer span {
    padding-left: 10px;
}
.incListHome{
    text-align: left;
}
.incListAway{
    text-align: right;
}
.rowTypePlayer{
    background-color: $focus-row;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0.25rem;
}

.loading{
    text-align: $text-center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    Img{
        width: 70px;
    }
}
.col-4.lineup{
    text-align: $text-center;
}
.col-6.lineupHome{
    float: left;
    width: 49%;
}
.blinkText {
    animation: blinker 1s linear infinite;
}
@keyframes blinker {
    50% {
      opacity: 0;
    }
  }
.popupAlert {
    position: fixed;
    left: 50%;
    z-index: 999;
    transform: translateX(-50%);
    top: 80%;
}
.showStatusData{
    max-width: 350px;
    padding: 10px;
    background: #00b36c;
    border-radius: 0.25rem;
    border: 2px solid #141e2e;
    border-style: dotted;
}
.bannertb {
    width: 90%;
    max-width: 300px;
}
.col-6.lineupAway{
    float: right;
    width: 49%;
}
.clearboth{
    clear: both;
}
.switchShow {
    display: inline;
    float: right;
    font-size: 13px;
    padding-right: 5px;
    padding-left: 5px;
    width: 10px;
    text-align: center;
    border: 1px solid #8ed2b0;
    border-radius: .25rem;
    cursor: pointer;
}
.running{
    width: 20px;
    height: 20px;
}
.h2hIconVertical{
    width: 20px;
    height: 29px;
    border-radius: 0.2rem;
    /*border: 1px solid;*/
    border: 1px solid #000000;
}
.h2hIcon{
    width: 25px;
    height: 17px;
    border-radius: 0.2rem;
    /*border: 1px solid;*/
    border: 1px solid #000000;
}
span.runningTime {
    vertical-align: super;
}
.resultTdedPointNoSuccess {
    border: 3px solid #ff3838;
    border-style: dotted;
    margin: 10px;
    padding: 10px;
}
.btnSwitchChart {
    max-width: 200px;
    background-color: #d41c44;
    cursor: pointer;
    padding: 5px;
    margin: 5px auto;
    margin-top: 10px;
    border-radius: 0.25rem;
}

.btnSwitchChartActive {
    max-width: 200px;
    background-color: #1ca5d4;
    cursor: pointer;
    padding: 5px;
    margin: 5px auto;
    margin-top: 10px;
    border-radius: 0.25rem;
}
.chartLeagueTable {
    /* font-size: 18pt; */
    background-color: rgba(255,255,255,1);
    border-radius: 0.5rem;
    margin: 10px;
    border: 2px solid #393c44;
}

/* start border animated */
.gradient-border {
--border-width: 1px;
position: relative;
    &::after {
        position: absolute;
        content: "";
        top: calc(-1 * var(--border-width));
        left: calc(-1 * var(--border-width));
        z-index: -2;
        width: calc(100% + var(--border-width) * 2);
        height: calc(100% + var(--border-width) * 2);
        background: linear-gradient(
        60deg,
        hsl(224, 85%, 66%),
        hsl(269, 85%, 66%),
        hsl(314, 85%, 66%),
        hsl(359, 85%, 66%),
        hsl(44, 85%, 66%),
        hsl(89, 85%, 66%),
        hsl(134, 85%, 66%),
        hsl(179, 85%, 66%)
        );
        background-size: 300% 300%;
        background-position: 0 50%;
        border-radius: calc(2 * var(--border-width));
        animation: moveGradient 4s alternate infinite;
    }
}
@keyframes moveGradient {
    50% {
        background-position: 100% 50%;
    }
}
/* end border animated */

/* start border shine */
.border-shine {
    padding: 1px;
    border-image: url(https://mvpkick.com/img/icon/border-shire.svg)1;
    }
@keyframes shine {
    to {
        background-position: 200% center;
    }
}
/* end border shine */

/* start progress anim v1 */
.boxProgress {
    //margin: 100px auto;
    text-align: center;
}

.progress-v1 {
    padding: 6px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 20px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar-v1 {	
    height: 18px;
    background-color: #ee303c;  
    border-radius: 4px; 
    transition: 0.4s linear;  
    transition-property: width, background-color;    
    color: white;
    font-weight: 600;
    font-size: smaller;
}

.progress-striped-orange .progress-bar-v1, .progress-striped-orange-re .progress-bar-v1 {
    background-color: #FCBC51; 
    width: 100%; 
    background-image: linear-gradient(45deg, rgb(252,163,17) 25%, transparent 25%, transparent 50%, rgb(252,163,17) 50%, rgb(252,163,17) 75%,transparent 75%, transparent); 
    animation: progressAnimationStrike 6s;
}
.progress-striped-green .progress-bar-v1, .progress-striped-green-re .progress-bar-v1 {
    background-color: #19c169; 
    width: 100%; 
    background-image: linear-gradient(45deg, #38d88e 25%, transparent 25%, transparent 50%, #11fc7a 50%, #37ce7a 75%, transparent 75%, transparent);
    animation: progressAnimationStrike 6s;
}
.progress-striped-green-re .progress-bar-v1, .progress-striped-orange-re .progress-bar-v1, .progress-striped-red-re .progress-bar-v1 {
    float:right;
}

.progress-striped-red .progress-bar-v1, .progress-striped-red-re .progress-bar-v1 {
    background-color: #f92a38; 
    width: 100%; 
    background-image: linear-gradient(45deg, #e46262 25%, transparent 25%, transparent 50%, #d85252 50%, #e46262 75%, transparent 75%, transparent);
    animation: progressAnimationStrike 6s;
}
.contentTded.progress-v1{
    padding: 0px;
}
.cTdedHome, .cTdedAway {
    vertical-align: top;
}
.btn-ctSelect{
    background-color: rgba(0,0,0,0.5);
    margin: 2px;
    padding: 2px;
}
@keyframes progressAnimationStrike {
    from { width: 0 }
    to   { width: 100% }
}
   
/* end progress anim v1 */

.chartH2h {
    vertical-align: middle;
    text-align: -webkit-right;
}
.percentResult{
    margin: 10px;
    font-size: 18px;
}
.wPercent,.dPercent,.lPercent{
    width: 100%;
    background-color: rgba(0,0,0,0.25);
    border-radius: 0.5rem;
    margin-bottom: 5px;
    margin-top: 5px;
}
.progressCol{
    vertical-align: middle;
}
.chartH2hInner {
    border: 5px solid #3d3d3d;
    float: right;
    padding-top: 50%;
    width: 50%;
    animation:spin 60s linear infinite;
}
@keyframes spin { 100% { transform:rotate(360deg); } }
.col-3.wdlCol {
    text-align: center;
    span{
        vertical-align: -webkit-baseline-middle;
    }
}
.fmt{
    div {
    display: inline-block;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    }
    //background-color: $bgListMatch;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #065a13;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: overlay;
}
.nameStanding {
    background: $bgGdMain;
    padding-top: 5px;
    padding-bottom: 5px;
}
.fmtPlayer{
    max-width: 30px;
    border-radius: 2rem;
    display: block;
    margin: auto;
    padding-bottom: 5px;
}
.btn-seeAll{
    background: linear-gradient(-45deg, #16a569 0, #07926c 100%);
    padding: 5px;
    border-radius: 0.25rem;
    margin: 10px;
    width: 90%;
}
.btn-seeAll:hover{
    background : $highlight-btn   
}
.statisticsLogo{
    max-width: 30px;
    border-radius: 2rem;
    margin: auto;
}
.flagLs {
    width: 20px;
    height: 15px;
    //padding-right: 5px;
    margin-right: 5px;
    border-radius: 0.25rem;
}
.logoTeamLs {
    width: 20px;
    height: 20px;
    padding-right: 5px;
    padding-left: 5px;
}
.logoTeamPage, .logoPlayerPage{
    width: 70px;
    height: 70px;
}
.logoTeamStatistics {
    width: 30px;
    height: 30px;
}
.logoRecCard{
    width: 50px !important;
}
.logoPlayerPage{
    border-radius: 2rem;
}
.cardRecMatch{
    //background-image: linear-gradient(180deg, #1a263c, #22374e);
    background-image: url(https://mvpkick.com/img/asset/texture-recommend-match.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    border-radius: 0.25rem;
    padding : 10px;
    min-height: 125px;
    transition: all .5s;
    //box-shadow: 5px 10px #1e1d30;
    
}
.boxRecMatch{
    margin: 5px;
    overflow: hidden;
    padding: 2px;
}
.cardFocus{
    display:none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 50%;
}
.cardRecMatch::before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.5;
}
.boxRecMatch:hover .cardRecMatch,.boxRecMatch:focus .cardRecMatch{
    transform: scale(1.2);
    .cardFocus{
        display: block;
    }
}
.boxRecMatch:hover .cardRecMatch::before,.boxRecMatch:focus .cardRecMatch::before{
    display: block;
}

.h2hMini{
    border-radius: 0.25rem;
    padding: 3px;
    color: white;
    border: 2px solid #14a36a;
}
.goToTop{
    vertical-align: top;
}
.overlay-bottom-to-top {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #232526 0, #2f3235 100%);;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
}
.cardRankTop:hover .overlay-bottom-to-top {
    height: 100%;
}
.overlay-bottom-to-top div div.col-5 {
    /* border: 1px solid white; */
    padding: 3px;
    background: $bgListMatchgd;
    border-radius: 0.25rem;
    margin: 3px;
}
.headingTdedPage h3{
    background-color: rgba(0,0,0,0.25);
    padding: 5px;
    border-radius: 0.5rem;
}
.dateTdedRow span {
    background-color: #202838;
    padding: 5px;
    border-style: dashed;
    border-color: #13141d;
    border-width: thin;
}
.switchHideInTdedPage{
    display: none;
}
.boxNewsLs, .boxTdedLs{
    text-align:center;
}
.boxNewsLs a div div.ThumbnailNews {
    min-height: 250px;
}
.headingNewsPage a {
    color: #39e466;
    text-decoration: none;
}
.listTded:hover {
    transform: rotateX(-10deg) rotateY(-5deg) translateY(-10px);
    background: linear-gradient(226deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.1) 35%, rgba(255,255,255,0.1) 42%, rgba(255,255,255,0) 60%);
    transition: all 0.4s;
}
.cmBtn {
    background-color: #1ca5d4;
    color: white;
    padding: 5px;
    border-radius: 0.25rem;
    width: 95%;
    margin-top: 5px;
    cursor: pointer;
    /* margin: auto; */
    border-width:0px;
}
.cmLogin{
    background-color: #e40a55;
    text-align: center;
    margin:auto;
    margin-top: 5px;
    width: 94%;
}
.oddsLs, .oddsLsBelow {
    color: #08bb7f;
    font-size: 9pt;
}
.oddsLs{
    cursor: pointer;
}
.sorryNoCalTded {
    background-color: #e4323d;
    width: 500px;
    margin: auto;
    padding: 10px;
    border-radius: 1rem;
    max-width: 80%;
}

.btn-vote .voteHome,.btn-vote .voteAway, .btn-vote .voteDraw, .btn-vote .votedActive, .btn-vote .voteLogin, .btn-vote .voteUser{
    border-radius: 0.25rem;
    padding: 5px;
    margin: 5px;
    font-size: 14px;
    cursor: pointer;
}
.btn-vote .voteHome{
    background: linear-gradient(45deg, #19c169, #19c169);
}
.btn-vote .voteAway {
    background: linear-gradient(45deg, #f92a38, #e46262);
}
.btn-vote .voteDraw {
    background: linear-gradient(45deg, #fca311, #fcbc51)
}
.btn-vote .votedActive{
    background: #0e1827;
    cursor: default;
}
.btn-vote .voteLogin, .btn-vote .voteUser{
    background: #ec2267;
    padding: 10px;
    
}
.btn-vote{
    margin-top: 20px;
    margin-bottom: 20px;
}
.playerImg{
    border-radius: 2rem;
    //height: 20px;
    width: 20px;
    margin-right: 5px;
}
.colTourteam-12 {
    width: 97%;
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #4040405c;
    margin: 2px;
}
.colTourteam-4 {
    width: 32%;
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #4040405c;
    margin: 2px;
}
.chertH2hLineAni {
    width: 100%;
    margin: auto;
    text-align: center;
}


.chertH2hTeam {
    width: 25%;
    margin: 10px;
    text-align: center;
    display: inline-block;
}



.colTourteam-3 {
    width: 23%;
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #4040405c;
    margin: 2px;
}
.tourteamTable{
    background-color: #00000059;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}
.headTourTeam {
    background-color: black;
    padding-top: 5px;
    padding-bottom: 5px;
}
.fmt-11,.fmt-115,.fmt-65,.fmt-95{width: 100%;}
.fmt-32,.fmt-34,.fmt-36,.fmt-38,.fmt-72,.fmt-74,.fmt-76,.fmt-78,.fmt-82,.fmt-84,.fmt-86,.fmt-88{width: 25%;}
.fmt-64,.fmt-66,.fmt-94,.fmt-96,.fmt-104,.fmt-106,.fmt-114,.fmt-116{width: 50%;}
.fmt-33,.fmt-35,.fmt-37,.fmt-83,.fmt-85,.fmt-87,.fmt-103,.fmt-105,.fmt-107{width: 33.33%;}
.fmt-71,.fmt-73,.fmt-75,.fmt-77,.fmt-79{width: 20%;}
/* fix fmt addon zone */
.fmt-31-5-3-2,.fmt-33-5-3-2,.fmt-35-5-3-2,.fmt-37-5-3-2,.fmt-39-5-3-2{width: 20%;}
.fmt-73-4-3-3,.fmt-75-4-3-3,.fmt-77-4-3-3,.fmt-73-5-3-2,.fmt-75-5-3-2,.fmt-77-5-3-2,.fmt-63-3-3-3-1,.fmt-65-3-3-3-1,.fmt-67-3-3-3-1{width: 33.33%;}
.fmt-84-4-1-2-3,.fmt-86-4-1-2-3{width: 50%;}
.fmt-74-4-1-2-1-2,.fmt-76-4-1-2-1-2{width: 50%;}
.fmt-63-4-3-1-2,.fmt-65-4-3-1-2,.fmt-67-4-3-1-2{width: 33.33%;}
.fmt-85-4-3-1-2{width: 100%;}
@media only screen and (max-width: 1300px) {
    .thumbTdedImg{
        min-height: 250px;
    }
    .tourStartTded {
        margin-top: auto;
        margin-bottom: 5px;
    }
}
@media only screen and (max-width: 1000px) {
    .thumbTdedImg{
        min-height: auto;
    }
    .topbar-nav{
        display: none;
    }
    .footerbar-nav{
        display: block;
    }
    .App{
        padding-top: 10px;
    }
    .col-8.mainLs{
        width: 100%;
    }
    .col-2.subLs{
        width: 100%;
    }
    .boxRecMatch.col-12 {
        width: 45%;
    }
    .footerBar{
        margin-bottom: 65px;
    }
    .col-9.progressStandingCol {
        display: none;
    }
    .col-3.standingCol {
        width: 100%;
    }
    /* s move element top to bottom */
    .containerLs {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -moz-box-align: start;
        -ms-flex-align: start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
    }
    .subLs {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    }
    .progressColTded {
        max-width: 60%;
    }
    /* e move element top to bottom */

    .cTdedMain{
        width: 100%;
    }
    .cTdedHome, .cTdedAway{
        width: 100%;
        display: flex;
        background-color: rgba(0,0,0,0.25);
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 0.25rem;
    }
    
    .H2hTopLogoTded {
        margin: auto;
    }
    .switchShowInTdedPage{
        display: none;
    }
    .switchHideInTdedPage{
        display: block;
    }
    
    .contentNewsDetails,.sidebarNews{
        width: 90%;
    }
}
@media only screen and (max-width: 900px) {
    .newsInContent {
        width: 40%;
    }
}
@media only screen and (max-width: 800px) {
    .my-3.px-3.col-8 {
        width: 90%;
    }
    .col-6.lineupAway,.col-6.lineupHome{
        width: 100%;
    }
    .fmt{
        div {
        padding-top: 20px;
        padding-bottom: 20px;
        }
    }

    .btn-seeAll{
        width: 90%;
    }
    
    .listTded.col-5{
        width: 90%;
    }
    .contentNewsDetails{
        margin: auto;
    }
    .tourNameTded, .tourStartTded span {
        font-size: 11pt;
    }
    .boxFunTong{
        width: 100%;
    }
    .funtongCommentTded.col-8{
        width: 90%
    }
    .percentResult{
        font-size: 14px;
    }
    
    .infoUser div.row div.col-5 {
        width: 100%;
        display: block;
        margin: auto;
    }
    
}
@media only screen and (max-width: 600px) {
    .newsInContent {
        width: 90%;
    }
}
@media only screen and (max-width: 500px) {
    .chertH2hTeam {
        width: 45%;
        margin: 5px;
        text-align: center;
        display: inline-block;
    }
    .col-2.league{
        width: 100%;
    }
    .col-3.wdlCol, .col-9.progressCol, .chartH2h{
        width: 100%;
    }
    .chartH2h{
        text-align: -webkit-center;
    }
    .chartH2hInner{
        float: none;
    }
    

}

@media only screen and (max-width: 450px) {
    .nav-item-footerbar {
        font-size: 12px;
    }
    .icon-nav {
        width: 22px;
        height: 22px;
        padding-right: 0px;
    }
}

@media only screen and (max-width: 350px) {
    .colTourteam-4,.colTourteam-3 {
        width: 48%;
    }
}
